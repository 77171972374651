$(document).ready(function() {
    var slidesSwiper = new Swiper('.swiper-container--slides', {
        loop: true,
        speed: 600,
        autoplay: {
            delay: 3500,
        },
        pagination: {
            el: '.swiper-pagination--slides',
            type: 'bullets',
            clickable: true,
        },
    });

    var miniaturasSwiper = new Swiper('.swiper-container--miniaturas', {
        slidesPerView: 2,
        spaceBetween: 5,
        simulateTouch: false,
        loop: true,
        navigation: {
            nextEl: '.miniaturas-button-next',
            prevEl: '.miniaturas-button-prev',
        },
        breakpoints: {
            500: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 5
            },
            1136: {
                slidesPerView: 8
            }
        }
    });

    $('.js-mobile-navbar-open-btn').on('click', function(e) {
        e.preventDefault();

        var $this = $(this);

        if ($('body').hasClass('__mobile-navbar-opened')) {
            $('body').removeClass('__mobile-navbar-opened');
            $this.html('<i class="ri ri-menu-line"></i>');
            $('.mobile-header__bottom').slideUp();
        } else {
            $('body').addClass('__mobile-navbar-opened');
            $this.html('<i class="ri ri-close-line"></i>');
            $('.mobile-header__bottom').slideDown();
        }
    });

    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 50) {
            $('body').addClass('__scrolled');
        } else {
            $('body').removeClass('__scrolled');
        }
    });

    if ($(window).scrollTop() > 50) {
        $('body').addClass('__scrolled');
    } else {
        $('body').removeClass('__scrolled');
    }
});

$(window).on('load', function() {
    $('.swiper-container--miniaturas > .swiper-wrapper').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
        },
    });
});
